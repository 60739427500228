import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { displayMonth } from '../../utils/dateformat';
import Loader from '../Loader';

function EventCard({
  title,
  image,
  startDate,
  promoter,
  id,
  size,
  onsale,
  provider,
  isFree,
  city,
  clock,
}) {
  const eventContent = useRef();
  const [isLoading, setLoading] = useState(true);
  const fixedPath = title
    .replace(/\s/g, '-')
    .replace(/-+/g, '-')
    .replace(/[^a-å0-9-]/gi, '')
    .toLowerCase();

  const handleDate = (date) => {
    let dates = date.split('-');
    if (provider === 'Musikhuset') {
      dates = date.split('T')[0].split('-');
    }
    const day = dates[2];
    const month = dates[1];
    const year = dates[0];

    return `${day}. ${displayMonth(month)} ${year}`;
  };

  const handleClock = (localTime) => {
    if (provider === 'Musikhuset') {
      return 'kl ' + startDate.split('T')[1].substring(0, 5);
    }

    const [hour, minute] = localTime.split(':');
    return `kl ${hour}.${minute}`;
  };

  const handleStatus = () => {
    if (isFree) return 'Gratis';
    if (title.toLowerCase().match('udsolgt')) return 'Udsolgt';
    if (title.toLowerCase().match('aflyst')) return 'Aflyst';
    if (provider === 'Ticketmaster' && onsale !== 'onsale')
      return 'Ikke aktuel';
    if (provider === 'Billetlugen' && onsale !== true) return 'Ikke aktuel';
    return 'Køb billet';
  };

  useEffect(() => {
    // handleImage();
  }, [image]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <article className={`event-container__event ${size}`}>
        <div ref={eventContent} className="event-content">
          {isLoading && <Loader />}
          {!isLoading && (
            <LazyLoadImage threshold={360 * 2} src={image} alt={title} />
          )}
          <div className="info">
            <Link to={`/events/${fixedPath}`}>
              <h2>{title}</h2>
            </Link>
            <p className="info__promoter">{promoter}</p>
            <p className="info__city">{city}</p>
            <div className="info__buyticket">
              <div>
                <p className="info__date">{handleDate(startDate)}</p>
                <p className="info__clock">
                  {(clock || provider === 'Musikhuset') && handleClock(clock)}
                </p>
              </div>
              <Link
                className={`btn info__buyticket-link${
                  title.toLowerCase().match('udsolgt') ||
                  title.toLowerCase().match('aflyst') ||
                  (provider === 'Ticketmaster' && onsale !== 'onsale') ||
                  (provider === 'Billetlugen' && onsale !== true)
                    ? ' offsale'
                    : ' onsale'
                }`}
                to={`/redirect/${id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{handleStatus()}</span>
              </Link>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

EventCard.defaultProps = {
  clock: PropTypes.null,
};

EventCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  promoter: PropTypes.string.isRequired,
  onsale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  city: PropTypes.string.isRequired,
  clock: PropTypes.string,
};

export default EventCard;
