import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loader from '../Loader';

function CommercialCard({ title, image, url }) {
  const eventContent = useRef();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <a
      className="event-container__event x1y1 com"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div ref={eventContent} className="event-content commercial">
        {isLoading && <Loader />}
        {!isLoading && (
          <LazyLoadImage threshold={360 * 2} src={image} alt={title} />
        )}
        <div className="info">
          <b className="suggestion">Oplev også</b>
          <h2>{title}</h2>
          <a
            className="btn info__buyticket-link onsale"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Læs mere</span>
          </a>
        </div>
      </div>
    </a>
  );
}

CommercialCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default CommercialCard;
