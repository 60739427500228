import React, { useContext, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import EventCard from '../EventCard';
import useSearch from '../../hooks/useSearch';
import { EventsContext } from '../../provider/EventProvider';
import throttle from '../../utils/throttle';
import CommercialCard from '../CommercialCard';

function EventContainer() {
  const { state } = useContext(EventsContext);
  const [pageNumber, setPageNumber] = useState(0);
  const [showBackToTop, setBackToTop] = useState(false);
  const [, handleScroll] = useSearch();

  const handleImage = (images, provider) => {
    if (provider === 'Billetlugen' || provider === 'Musikhuset') {
      return images;
    }

    const image = images.filter((e) => e.width === 640);
    return image[0].url;
  };

  const showScrollToTop = () => {
    if (window.scrollY > 320) {
      setBackToTop(true);
    } else {
      setBackToTop(false);
    }
  };

  // Next page 2 rows before bottom. 360px height for each row
  const containerHeight = 360 * 2;
  let number = 0;
  let latestHeight = 0;
  const handlePage = () => {
    if (number >= state.totalPages) return;

    // const bottomHitted =
    //   window.innerHeight + window.scrollY >= document.body.offsetHeight;
    // if (bottomHitted) {
    //   setLoading(true);
    // } else {
    //   setLoading(false);
    // }

    const bodyHeight = document.body.offsetHeight;
    const loadNextPage =
      window.innerHeight + window.scrollY >= bodyHeight - containerHeight;

    if (loadNextPage && bodyHeight > latestHeight) {
      // Avoid calling search before new events are rendered - will cause double search on slow network
      latestHeight = bodyHeight;
      number++;
      setPageNumber(number);
    }
  };

  useEffect(() => {
    // setPageNumber(0);
    // number = 0;
    document.addEventListener('scroll', throttle(handlePage, 200));
    document.addEventListener('scroll', throttle(showScrollToTop, 200));

    return () => {
      document.removeEventListener('scroll', throttle(handlePage));
      document.removeEventListener('scroll', throttle(showScrollToTop));
    };
  }, [state.totalPages]);

  useEffect(() => {
    // Avoid double search on first render
    if (
      pageNumber === 0 ||
      pageNumber > state.totalPages ||
      state.events.length >= state.totalEvents
    )
      return;

    handleScroll(pageNumber);
  }, [pageNumber]);

  const handleSize = (images, provider) => {
    if (provider === 'Billetlugen' || provider === 'Musikhuset') {
      return 'x1y1';
    }

    const [image] = images.filter((e) => e.width === 640);

    if (image.height === 360) {
      return 'x2y1';
    }

    return 'x1y1';
  };

  return (
    <>
      <section className="event-container">
        {state.events &&
          state.events.map((event, key) => (
            <React.Fragment
              key={event.id ? `${event.id}-${event.provider}` : event.url}
            >
              {event.isCommercial ? (
                <CommercialCard
                  title={event.title}
                  url={event.url}
                  image={event.image}
                />
              ) : (
                <EventCard
                  item={key}
                  id={event.id}
                  title={event.title}
                  clock={event.clock}
                  onsale={event.onsale}
                  image={handleImage(event.image, event.provider)}
                  size={handleSize(event.image, event.provider)}
                  startDate={event.date}
                  promoter={event.venue}
                  provider={event.provider}
                  city={event.city}
                  postalCode={event.zipCode}
                  url={event.url}
                  isFree={event.isFree}
                />
              )}
            </React.Fragment>
          ))}
      </section>
      {showBackToTop && (
        <Fade>
          <button
            onClick={() => window.scrollTo(0, 0)}
            className="go-to-top active"
          >
            <i />
          </button>
        </Fade>
      )}
    </>
  );
}

export default EventContainer;
